import React from 'react';
import { FaTruck, FaBox, FaPlane, FaWarehouse, FaClipboardCheck, FaRoute } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import './WhatWeDo.css';

const WhatWeDo = () => {
  const { t } = useTranslation();

  const blocks = [
    {
      icon: <FaTruck size={50} />,
      title: t('international_shipping'),
      description: t('international_shipping_desc'),
    },
    {
      icon: <FaBox size={50} />,
      title: t('container_shipping'),
      description: t('container_shipping_desc'),
    },
    {
      icon: <FaPlane size={50} />,
      title: t('air_shipping'),
      description: t('air_shipping_desc'),
    },
    {
      icon: <FaWarehouse size={50} />,
      title: t('logistics'),
      description: t('logistics_desc'),
    },
    {
      icon: <FaClipboardCheck size={50} />,
      title: t('custom_clearance'),
      description: t('custom_clearance_desc'),
    },
    {
      icon: <FaRoute size={50} />,
      title: t('freight_forwarding'),
      description: t('freight_forwarding_desc'),
    },
  ];

  return (
    <div className="what-we-do">
      <div className="blocks-grid">
        {blocks.map((block, index) => (
          <div key={index} className="block">
            <div className="block-content">
              <div className="icon-container">
                {block.icon}
              </div>
              <h2 className="block-title">{block.title}</h2>
              <p className="block-description">{block.description}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default WhatWeDo;