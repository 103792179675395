import React, { useState, useEffect } from 'react';
import { FaUserCircle } from 'react-icons/fa';
import { ReactTyped as Typed } from 'react-typed';
import { useTranslation } from 'react-i18next';
import './HeroSection.css';

const HeroSection = () => {
  const { t } = useTranslation();
  const descriptions = [
    t('hero_description_1'),
    t('hero_description_2'),
    t('hero_description_3'),
    t('hero_description_4'),
    t('hero_description_5'),
    t('hero_description_6'),
    t('hero_description_7'),
    t('hero_description_8'),
  ];

  const [index, setIndex] = useState(0);
  const [key, setKey] = useState(0);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setKey((prevKey) => prevKey + 1);
      setIndex((prevIndex) => (prevIndex + 1) % descriptions.length);
    }, 20000);

    return () => clearTimeout(timeout);
  }, [index]);

  return (
    <div className="hero-section">
      <div className="hero-content">
        <div className="hero-chat-bubble">
          <FaUserCircle className="hero-chat-icon" />
          <Typed
            key={key}
            strings={[descriptions[index]]}
            typeSpeed={40}
            backSpeed={22}
            backDelay={9000}
            startDelay={3000}
            showCursor={true}
            loop={true}
          />
        </div>
      </div>
    </div>
  );
};

export default HeroSection;