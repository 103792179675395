import React from 'react';
import './WorkProcess.css';
import { FaPencilAlt, FaFileAlt, FaTruck, FaCogs, FaEye, FaClipboardCheck, FaFlagCheckered, FaHeadset } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';

const WorkProcess = () => {
  const { t } = useTranslation();

  const steps = [
    { name: t('planning'), icon: <FaPencilAlt /> },
    { name: t('documents_preparation'), icon: <FaFileAlt /> },
    { name: t('vehicle_selection'), icon: <FaTruck /> },
    { name: t('transport_organization'), icon: <FaCogs /> },
    { name: t('monitoring'), icon: <FaEye /> },
    { name: t('custom_clearance'), icon: <FaClipboardCheck /> },
    { name: t('delivery_unloading'), icon: <FaFlagCheckered /> },
    { name: t('client_support'), icon: <FaHeadset /> },
  ];

  return (
    <div className="work-process-container">
      <h2 className="work-process-title">{t('work_process')}</h2>
      <div className="work-process">
        {steps.map((step, index) => (
          <div key={index} className="work-process-step">
            <div className="work-process-icon">
              {step.icon}
            </div>
            <p className="work-process-name">{step.name}</p>
            {index < steps.length - 1 && <div className="work-process-arrow">→</div>}
          </div>
        ))}
      </div>
    </div>
  );
};

export default WorkProcess;