import React from 'react';
import './Footer.css';
import { FaPhone, FaEnvelope, FaMapMarkerAlt, FaArrowUp } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';

const Footer = () => {
  const { t } = useTranslation();

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <footer className="footer">
      <div className="footer-content">
        <div className="footer-contacts">
          <h3>{t('footer_contacts_title')}</h3>
          <p><FaPhone /> {t('footer_phone')}: +7 (962) 280-21-81</p>
          <p><FaEnvelope /> {t('footer_email')}: regitrans@mail.ru</p>
          <p><FaMapMarkerAlt /> {t('footer_address')}: 683002,  Камчатский край, г. Петропавловск-Камчатский, пр.Таранца 5, кВ 54</p>
        </div>
        <div className="footer-copy">
          <p>&copy; {new Date().getFullYear()} REGIONTRANS. {t('footer_copy')}</p>
        </div>
        <button className="scroll-to-top" onClick={scrollToTop}>
          <FaArrowUp />
        </button>
      </div>
    </footer>
  );
};

export default Footer;