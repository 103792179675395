import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { useTranslation } from 'react-i18next';  // Добавляем для работы с переводами
import Header from './components/Header';
import HeroSection from './components/HeroSection';
import WhatWeDo from './components/WhatWeDo';
import Partners from './components/Partners';
import WhyChooseUs from './components/WhyChooseUs';
import Footer from './components/Footer';
import Services from './components/Services';
import WorkProcess from './components/WorkProcess';
import './App.css';
import './i18n';  // Добавляем i18n

function App() {
  const { t, i18n } = useTranslation();

  useEffect(() => {
    // Меняем заголовок страницы при изменении языка
    document.title = t('page_title');
  }, [t, i18n.language]);

  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/" element={
            <>
              <Header />
              <HeroSection />
              <WhatWeDo />
              <WhyChooseUs />
              <WorkProcess />
              {/* <Partners /> */}
              <Footer />
            </>
          } />
          <Route path="/services" element={
            <>
              <Header />
              <Services />
              <Footer />
            </>
          } />
        </Routes>
      </div>
    </Router>
  );
}

export default App;