import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import './Header.css';
import { FaEnvelope, FaMapMarkerAlt, FaFlag, FaFlagUsa, FaGlobe } from 'react-icons/fa';

const Header = () => {
  const { t, i18n } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const changeLanguage = (lang) => {
    i18n.changeLanguage(lang);
  };

  return (
    <>
      <header className="header">
        <div className="container">
          <Link to="/" className="logo">
            {t('company_name')}
          </Link>
          <div className="info">
            <div className="info-item">
              <FaEnvelope className="icon" />
              <span> regitrans@mail.ru</span>
            </div>
            <div className="info-item">
              <FaMapMarkerAlt className="icon" />
              <span>{t('location')}</span>
            </div>
          </div>
          <div className="buttons-container">
            <Link to="/services" className="services-button">
              {t('services')}
            </Link>
            <a href="tel:+79622802181" className="cta-button">
              {t('call_us')}
            </a>
            <div className="language-buttons">
              <button onClick={() => changeLanguage('ru')}>
                <FaFlag className="icon" /> RU
              </button>
              <button onClick={() => changeLanguage('en')}>
                <FaFlagUsa className="icon" /> EN
              </button>
              <button onClick={() => changeLanguage('zh')}>
                <FaGlobe className="icon" /> ZH
              </button>
            </div>
          </div>
        </div>
      </header>
    </>
  );
};

export default Header;