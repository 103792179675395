import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import translationEN from './locales/en.json';
import translationRU from './locales/ru.json';
import translationZH from './locales/zh.json';

const resources = {
  en: {
    translation: translationEN
  },
  ru: {
    translation: translationRU
  },
  zh: {
    translation: translationZH
  }
};

i18n
  .use(initReactI18next) 
  .init({
    resources,
    lng: 'ru', 
    fallbackLng: 'en', 
    interpolation: {
      escapeValue: false 
    }
  });

export default i18n;