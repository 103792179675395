import React from 'react';
import './Services.css';
import { FaBuilding, FaPhone, FaEnvelope, FaMoneyBill, FaPassport } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';

const requisites = [
  {
    name: 'company_name_rekvizit',
    icon: <FaBuilding />
  },
  {
    name: 'phone_rekvizit',
    value: 'phone_value_rekvizit',
    icon: <FaPhone />
  },
  {
    name: 'email_rekvizit',
    value: 'email_value_rekvizit',
    icon: <FaEnvelope />
  },
  {
    name: 'inn_rekvizit',
    value: 'inn_value_rekvizit',
    icon: <FaMoneyBill />
  },
  {
    name: 'ogrn_rekvizit',
    value: 'ogrn_value_rekvizit',
    icon: <FaPassport />
  },
  {
    name: 'kpp_rekvizit',
    value: 'kpp_value_rekvizit',
    icon: <FaMoneyBill />
  },
  {
    name: 'address_rekvizit',
    value: 'address_value_rekvizit',
    icon: <FaBuilding />
  }
];

const Services = () => {
  const { t } = useTranslation();

  return (
    <div className="services">
      <h2>{t('services')}</h2>
      <div className="requisites-block">
        {requisites.map((req, index) => (
          <div key={index} className="requisite-item">
            <div className="requisite-icon">{req.icon}</div>
            <div className="requisite-info">
              <p className="requisite-label"><strong>{t(req.name)}:</strong></p>
              <p className="requisite-value">{t(req.value || req.name)}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Services;