import React from 'react';
import { FaGlobe, FaShippingFast, FaTasks, FaClipboardList, FaShieldAlt, FaLaptopCode } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import './WhyChooseUs.css';

const WhyChooseUs = () => {
  const { t } = useTranslation();

  const benefits = [
    {
      icon: <FaGlobe />,
      title: t('global_coverage'),
      description: t('global_coverage_desc'),
    },
    {
      icon: <FaShippingFast />,
      title: t('speed_reliability'),
      description: t('speed_reliability_desc'),
    },
    {
      icon: <FaTasks />,
      title: t('logistics_solutions'),
      description: t('logistics_solutions_desc'),
    },
    {
      icon: <FaClipboardList />,
      title: t('custom_support'),
      description: t('custom_support_desc'),
    },
    {
      icon: <FaShieldAlt />,
      title: t('risk_management'),
      description: t('risk_management_desc'),
    },
    {
      icon: <FaLaptopCode />,
      title: t('technology_innovation'),
      description: t('technology_innovation_desc'),
    },
  ];

  return (
    <div className="why-choose-us-section">
      <h2 className="section-title">{t('why_choose_us')}</h2>
      <div className="why-choose-us">
        {benefits.map((benefit, index) => (
          <div key={index} className="benefit-card">
            <div className="icon-container">{benefit.icon}</div>
            <h3 className="benefit-title">{benefit.title}</h3>
            <p className="benefit-description">{benefit.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default WhyChooseUs;